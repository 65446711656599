import React from 'react'

function AppBannerSubPage() {
  return (
    <section>
        <div id='backImageSubPage' className="banner position-relative">
        <div className='title-banner' id='title-unidade' >
        <h1>Unidade Orgânica</h1>
        </div>
        </div>
    </section>
  )
}

export default AppBannerSubPage
