import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const blogData = [
  {
    id: 1,
    image: require('../assets/Foto/Unidade_OR_Eng.jpg'),
    title: 'Unidade Orgânicas de Engenharia',
    description: 'Descubra como o curso de Engenharia pode abrir portas para uma carreira emocionante e impactante.',
    link: '#'
  },
  {
    id: 2,
    image: require('../assets/Foto/Unidade_OR_cie.jpg'),
    title: 'Unidade Orgânicas de Ciências',
    description: 'Desbloqueie seu potencial de liderança com o curso de Ciência',
    link: '#'
  },
  {
    id: 3,
    image: require('../assets/Foto/Unidade_OR_sau.jpg'),
    title: 'Unidade Orgânicas de Saúde',
    description: 'Nosso curso de saúde oferece uma perspectiva única e abrangente sobre questões que moldam o mundo em que vivemos.',
    link: '#'
  }
  ,
  {
    id: 3,
    image: require('../assets/Foto/Unidade_OR_human.jpg'),
    title: 'Unidade Orgânicas de Economias Jurídicas e Humanas ',
    description: 'NossoS curso de Economias Jurídicas e Humanas Oferece uma visão aprofundada das estruturas, dinâmicas e desafios que moldam nossa sociedade.',
    link: '#'
  }
]

function AppUdadeOrganica() {
  const btn_viewsPages={
    marginLeft: 40
  }
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
        </div>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={3} key={blog.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                        <a style={btn_viewsPages} href={blog.link} className="btn btn-primary">Leia mais<i className="fas fa-chevron-right"></i></a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default AppUdadeOrganica;