import React from 'react';
import AppHeader from '../../../components/Header';
import AppUdadeOrganica from '../../../components/unidadeOrganica';
import AppFooter from '../../../components/footer';
import AppBannerSubPage from '../../../components/bannerSubPage';

function Unidade_organica() {
    return (
        <>
        <div className="App">
        <header id='header'>
        <AppHeader />
        </header>
        <AppBannerSubPage />
        <AppUdadeOrganica />
        <footer id="footer">
        <AppFooter />
      </footer>
        </div>

    </>
     )
}

export default Unidade_organica;