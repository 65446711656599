import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import AppBannerPage from '../../../components/bannerPage';

function Qualidade() {
    return (
        <div>
        <header id='header'>
        <AppHeader />
      </header>
      <AppBannerPage />
        <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine">
                <p className="f-reg">
                    <b className=" title-apresentation display-6 mb-3" style={{ color:'#97be4a'}}>Actuação Científica e Pedagógica</b><br />
                    <br />
                    Com cursos legalizados pelo Decreto nº 148/22 de 9 de março, teve início da sua actividade académica em 2017 com alunos, destribuídos em seguintes cursos:
                    <br />
                    <i class="fa-solid fa-circle" style={{color: '#97be4a'}}></i> 1º UNIDADE ORGANICA DE CIÊNCIAS E ECONOMIAS JURÍDICAS E HUMANAS
                    <br />
                    a) Ensino Primário
                    <br />
                    b) Psicologia
                    <br />
                    c) Sociologia
                    <br />
                    d) Gestão de Recursos Humanos
                    <br />
                    e) Gestão de Empresas
                    <br />
                    f) Economia
                    <br />
                    <i class="fa-solid fa-circle" style={{color: '#97be4a'}}></i> 2º UNIDADE ORGÂNICA DE SAÚDE
                    <br />
                    a) Enfermagem
                    <br />
                    <i class="fa-solid fa-circle" style={{color: '#97be4a'}}></i> 3º UNIDADE ORGÂNICA DE ENGENHARIA
                    <br />
                    a) Engenharia Informática
                    <br />
                    b) Arquitetura
                </p>
                <p>
                    As unidades orgânicas possuem um diretor com grau académicode PHD, na categoriade professor auxiliar, este que acumula como assistente administrativo do vice presidente acadêmico.
                    <br />
                    <br />
                    Possuem um Diretor adjunto para assuntos acadêmicos e vida estudantil com grau de mestre na categoria de assistente e um Diretor adjunto para assuntos científicos e pós-graduação também com grau académico de mestre na categoria de assistente.
                    <br />
                    <br />
                    Possuem funcionários técnicos administrativos e docentes dos com graus académicos de professor PHD, Mestres e Licenciados, efectivos e colaboradores como oriente no Decreto 310/20 de 7 de Dezembro. Desde as categorias de professores associados, auxiliares, assistentes e assistentes estagiários.
                    <br />
                    <br />
                    Outrossim, as mesmas unidades possuem laboratórios e convénios com instituições públicas e privadas para práticas e estágios dos alunos.
                </p>
                <br />
                </div>
                <br />
                <br />
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/instituicao" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Apresentação
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/missao_visao_valores" style={{textDecoration: 'none'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Missão, Visão e Valores
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a> 
                <a href="/Qualidade" style={{textDecoration: 'none', borderBottom: '1px solid green', borderTop: '1px solid green'}} className="py-2 text-dark f-reg d-flex justify-content-between">
                <span className="f-reg " style={{ color:'#97be4a'}}>
                    Qualidade
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>     
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </main>
        <AppLayout />
        </div>

    );
}

export default Qualidade;