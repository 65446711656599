import React from 'react';
import AppHeader from '../../components/Header';
import AppLayout from '../../components/layout';
import AppBannerPage from '../../components/bannerPage';
import Appestatistics from '../../components/estatistics';


function Instituicao() {
    return (
        <>
        <header id='header'>
        <AppHeader />
        </header>
        <AppBannerPage />
            <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine" id='detalhes'>
                    <p className="f-reg">
                    <b className="title-apresentation display-6 mb-3"style={{ color:'#97be4a'}}>Apresentação</b>
                    <br />
                    <br />
                    O Instituto Superior Politécnico Privado do Luena(ISPPL), FOI fundadoem 2014 e legalizado aos 03 de Agosto de 2017, pelo Decreto Presidencial nº 173/17 com a entidade promotora Carloide e Filhos. Dirigido por um presidente com grau de PHD, na categoria de professor associado, e um vice-presidente para assuntos académicos e vida estudantil com grau académico de PHD, na categoriade professor auxiliar, Vice-presidente para assuntos científicos e pós-graduação com grau académico de PHD, na categoria de profesor auxiliar, todos devidamente homologados pelo ministério do ensino superior. 
                    <br />
                    <br />
                    <p>A instituicão está constituide de três áreas do conhecimento: Ciências da gestão, Ciências das Engenharias e Tecnologia,Ciência Sociais. Ao todo, são ministrados 17 cursos de graduação: Gestão de Contabilidade,Gestão de Empresa, Gestão de Recursos Humanos, Ciências Farmacêuticas, Análises Clínicas, Enfermagem, Fisioterapia, Direito, Agregação pedagógica, Ensino Primário, Economia, Psicologia, Relações Internacionais, Sociologia, Engenharia Informática, Engenharia Civil , Engenharia Arquitetura.</p>
                    <br />
                    </p>
                </div>
                <b className="title-apresentation display-7 mb-3"style={{ color:'#97be4a'}}>Histórico em Números</b>
                <h6 style={{color: '#000000'}}>O Instituto Superior Politécnico do Luena nos últimos 4 anos </h6>
                <p>Ano: <strong style={{color:'#97be49'}}>2021-2024</strong></p>
                <br />
                <br />
                <Appestatistics />
                <br />
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/instituicao" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg " style={{ color:'#97be4a'}}>
                    Apresentação
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/missao_visao_valores" style={{textDecoration: 'none'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Missão, Visão e Valores
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>   
                <a href="/Qualidade" style={{textDecoration: 'none', borderBottom: '1px solid green', borderTop: '1px solid green'}} className="py-2 text-dark f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Qualidade
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>          
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </main>
        <AppLayout />
        </>
       
    );
}

export default Instituicao;