import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

<i class="fa-solid fa-book"></i>

const servicesData = [
    {
      id: 1,
      icon: 'fas fa-graduation-cap',
      title: 'Nº de Alunos',
      description: '1905',
      date:'Ano: 2021'
    },
    {
      id: 2,
      icon: 'fas fa-user-tie',
      title: 'Funcionários Administrativos e Gerais',
      description: '84',
      date:'Ano: 2021'
    },
    {
      id: 3,
      icon: 'fas fa-person-chalkboard',
      title: 'Docentes',
      description: '',
      date:'Ano: 2021'
    },
    {
      id: 4,
      icon: 'fas fa-book',
      title: 'Cursos',
      description: '',
      date:'Ano: 2021'
    },
    {
      id: 4,
      icon: 'fas fa-graduation-cap',
      title: 'Nº de Alunos',
      description: '1706',
      date:'Ano: 2022'
    },
    {
      id: 4,
      icon: 'fas fa-user-tie',
      title: 'Funcionários Administrativos e Gerais',
      description: '84',
      date:'Ano: 2022'
    },
    {
      id: 4,
      icon: 'fas fa-person-chalkboard',
      title: 'Docentes',
      description: '65',
      date:'Ano: 2022'
    },
    {
      id: 4,
      icon: 'fas fa-book',
      title: 'Cursos',
      description: '11',
      date:'Ano: 2022'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-graduation-cap',
      title: 'Nº de Alunos',
      description: '1349',
      date:'Ano: 2023'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-user-tie',
      title: 'Funcionários Administrativos e Gerais',
      description: '81',
      date:'Ano: 2023'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-person-chalkboard',
      title: 'Docentes',
      description: '65',
      date:'Ano: 2023'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-book',
      title: 'Cursos',
      description: '11',
      date:'Ano: 2023'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-graduation-cap',
      title: 'Nº de Alunos',
      description: '1430',
      date:'Ano: 2024'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-user-tie',
      title: 'Funcionários Administrativos e Gerais',
      description: '78',
      date:'Ano: 2024'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-person-chalkboard',
      title: 'Docentes',
      description: '60',
      date:'Ano: 2024'
    }
    ,
    {
      id: 4,
      icon: 'fas fa-book',
      title: 'Cursos',
      description: '11',
      date:'Ano: 2024'
    }
  ]
  
function Appestatistics() {
  return (
<section id="services" className="block services-block" style={{marginTop:'-90px'}}> 
      <Container fluid>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={3} className='holder' key={services.id} style={{border:'1px solid #97be4a',borderRadius:'12px'}}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3 style={{color:'#00761F'}}>{services.title}</h3>
                  <h1>{services.description}</h1>
                  <p>{services.date}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default Appestatistics