import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';

var testimonialsData = [
  {
    id: 1,
    name: 'Maria Delfina Santos',
    description: 'Escolhi o Instituto Superior Politécnico Privado Do Luena porque, desde o primeiro momento, percebi que ele realmente se importa com o nosso futuro. A qualidade do ensino é impressionante, com professores altamente qualificados que estão sempre dispostos a ajudar. Além disso, o ambiente é super dinâmico e colaborativo, o que me motiva a aprender ainda mais. Tenho certeza de que este instituto foi a escolha certa para a minha formação e para abrir portas no mercado de trabalho',
    designation: 'Estudante'
  },
  {
    id: 2,
    name: 'Clara Ferreira',
    description: 'Estudar no Instituto Superior Politécnico Privado Do Luena tem sido uma experiência transformadora. O curso de pedagofia não só me deu uma sólida base teórica, mas também me preparou para o mundo real através de estágios e simulações de mercado. O ambiente universitário é dinâmico, com diversas atividades extracurriculares que me ajudaram a desenvolver habilidades de liderança e trabalho em equipe. O suporte contínuo dos professores e a proximidade com o setor empresarial são grandes diferenciais. Eu recomendo vivamente este instituto para quem quer ter uma educação de qualidade e uma carreira de sucesso.',
    designation: 'Estudante'
  },
  {
    id: 3,
    name: 'João Mendes',
    description: 'Estudar no Instituto Superior Politécnico Privado Do Luena foi a escolha certa para minha carreira. O curso de Engenharia que estou a fazer não é apenas teórico; desde o início, temos a oportunidade de aplicar o que aprendemos em projetos reais, o que faz toda a diferença. Os laboratórios são equipados com tecnologia de última geração e os professores têm uma abordagem muito acessível. A qualidade do ensino, aliada a uma rede de parcerias com empresas, garante que eu tenha uma ótima preparação para o mercado. Sem dúvida, estou no caminho certo para alcançar meus objetivos profissionais.',
    designation: 'Estudante'
  }
]
function AppTestimonials() {
  return (
    <section id="testimonials" className="testimonials-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Depoimentos</h2>
          <div className="subtitle">O Que As Pessoas Dizem</div>
        </div>
        <Carousel controls={false}>
          {
            testimonialsData.map(testimonials => {
              return (
                <Carousel.Item key={testimonials.id}>
                  <blockquote>
                    <p>{testimonials.description}</p>
                    <cite>
                      <span className='name'>{testimonials.name}</span>
                      <span className='designation'>{testimonials.designation}</span>
                    </cite>
                  </blockquote>             
                </Carousel.Item>
              );
            })
          }
        </Carousel>
      </Container>
    </section>
  );
}

export default AppTestimonials;