import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import img1 from '../assets/Foto/instituition_01.png';

function AppAbout() {

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Percurso Histórico</h2>
          <div className="subtitle">Nossa História</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image className='image_Quem' src={img1} />
          </Col>
          <Col sm={6}>
            <p>O Instituto Superior Politécnico Privado do Luena(ISPPL), FOI fundadoem 2014 e legalizado aos 03 de Agosto de 2017, pelo Decreto Presidencial nº 173/17 com a entidade promotora Carloide e Filhos. Dirigido por um presidente com grau de PHD, na categoria de professor associado, e um vice-presidente para assuntos académicos e vida estudantil com grau académico de PHD, na categoriade professor auxiliar, Vice-presidente para assuntos científicos e pós-graduação com grau académico de PHD, na categoria de profesor auxiliar, todos devidamente homologados pelo ministério do ensino superior. </p>
            <p>A instituicão está constituide de três áreas do conhecimento: Ciências da gestão, Ciências das Engenharias e Tecnologia,Ciência Sociais. Ao todo, são ministrados 17 cursos de graduação: Gestão de Contabilidade,Gestão de Empresa, Gestão de Recursos Humanos, Ciências Farmacêuticas, Análises Clínicas, Enfermagem, Fisioterapia, Direito, Agregação pedagógica, Ensino Primário, Economia, Psicologia, Relações Internacionais, Sociologia, Engenharia Informática, Engenharia Civil , Engenharia Arquitetura.</p>
            <a className="btn btn-primary" href='/Instituicao '>Leia Mais <i className="fas fa-chevron-right"></i></a>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;