import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/logo.png';

function AppHeader() {
  return (
    <section>  
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
        <img className='logoHome' src={Logo} alt="Minha Imagem" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link  style={{textDecoration: 'none',fontSize: '20px'}} href="/">Home</Nav.Link>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-ispa"
              title="Sobre nós"
              menuVariant=""
            >
              <NavDropdown.Item className='item_nav' href="/instituicao"> Apresentação</NavDropdown.Item>
              <NavDropdown.Item href="/missao_visao_valores" >Organização</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/Qualidade" >Qualidade</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Programas"
              menuVariant=""
            >
              <NavDropdown.Item href="/">Licenciatura</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/Unidade_organica" > Unidade Orgânica</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Cursos"
              menuVariant=""
            >
              <NavDropdown.Item href="/">Ciências Sócias e Humanas</NavDropdown.Item>
              <NavDropdown.Item href="/" >Saúde</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" > Engenharias</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Centro de Pesquisa"
              menuVariant=""
            >
              <NavDropdown.Item href="/"> Unidade de Investigação</NavDropdown.Item>
              <NavDropdown.Item href="/">Revistas e Editoras</NavDropdown.Item>
              <NavDropdown.Item href="/"> Observatórios</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" > Apoio a Investigação</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Recursos"
              menuVariant=""
            >
              <NavDropdown.Item href="/"> Bibliotecas</NavDropdown.Item>
              <NavDropdown.Item href="/"> Portal do Estudante</NavDropdown.Item>
              <NavDropdown.Item href="/"> Laboratórios</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" > Bibliotecas</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://portalluena.icsgisabelinha.com/" target='_blank'  style={{textDecoration: 'none',fontSize: '20px'}}> Portal Online </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </section>
  );
}

export default AppHeader;