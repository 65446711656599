import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/Foto/Ortorga_03.jpeg'),
    title: 'Festa de Ortorga no Instituto Superior Politécnico Privado do Luena',
    description: 'Com grande entusiasmo a sua tradicional Festa de Ortorga, um evento anual que marca um dos momentos mais esperados do calendário acadêmico. O campus foi transformado em um vibrante espaço de celebração, onde alunos, professores e funcionários se reuniram para um dia repleto de atividades e confraternização',
    link: '#'
  },
  {
    id: 2,
    image: require('../assets/Foto/Ortorga_04.jpeg'),
    title: 'Celebração de Conquistas',
    description: 'O impacto da Festa de Ortorga foi visível na alegria e entusiasmo de todos os presentes. Além de proporcionar um alívio do ritmo intenso dos estudos, o evento fortaleceu os laços entre membros da comunidade acadêmica e celebrou o espírito colaborativo que caracteriza o Instituto Superior Ortorga',
    link: '#'
  },
  {
    id: 3,
    image: require('../assets/Foto/Ortorga_05.jpeg'),
    title: 'Celebrando Conquistas',
    description: 'É hora de celebrar o sucesso e o talento dos nossos alunos na Cerimônia de Outorga do ISPP-LUENA. Neste evento especial, reconhecemos e homenageamos as realizações acadêmicas, artísticas e pessoais que tornam nossa comunidade tão excepcional',
    link: '#'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p style={{color:'#FFFFFF'}}>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Leia Mais <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;