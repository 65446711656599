import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

const worksData = [
  {
    id: 1,
    link: '#',
    image: require('../assets/Foto/Comemoration_01.jpeg'),
    title: 'Festa de aniversário do ISPPLUENA',
    subtitle: 'A instutuição se transformou em um verdadeiro palco de celebração com a realização da festa anual, um evento aguardado com entusiasmo por alunos, professores e familiares. Desde o início da tarde, o ambiente estava repleto de uma energia contagiante. A entrada foi adornada com balões coloridos e faixas vibrantes, criando um clima festivo que contagia a todos.'
  },
  {
    id: 2,
    link: '#',
    image: require('../assets/Foto/active_cult_01.jpeg'),
    title: ' Atividade Cultural',
    subtitle: 'A instituição escolar promoveu uma atividade cultural vibrante e enriquecedora, que ofereceu aos alunos e à comunidade escolar uma experiência imersiva na diversidade e na riqueza cultural. Desde cedo, o ambiente foi preenchido com uma aura de expectativa e entusiasmo.'
  },
  {
    id: 3,
    link: '#',
    image: require('../assets/Foto/active_cult_02.jpeg'),
    title: 'Feira da Literatura',
    subtitle: 'Uma Jornada Literária” faz parte da nossa Feira de Literatura e convida participantes de todas as idades a explorar e celebrar o fascinante universo dos livros e da escrita.'
  },
  {
    id: 4,
    link: '#',
    image: require('../assets/Foto/Alun_01.jpeg'),
    title: 'Entrega de Diplomas',
    subtitle: 'Na palestra sobre a violência doméstica, realizada em uma data não especificada, especialistas e ativistas se reuniram para discutir esse tema crucial.'
  },
  {
    id: 5,
    link: '#',
    image: require('../assets/Foto/active_cult_04.jpeg'),
    title: 'Palestras Inspiradoras',
    subtitle: 'Nossas palestras são eventos dinâmicos e enriquecedores projetados para expandir horizontes e fomentar o aprendizado contínuo. Cada sessão é conduzida por especialistas renomados em suas áreas, abordando uma ampla gama de temas que vão desde inovações tecnológicas e descobertas científicas até questões sociais e culturais relevantes.'
  },
  {
    id: 6,
    link: '#',
    image: require('../assets/Foto/instituition_03.jpeg'),
    title: 'Atividade de artes',
    subtitle: 'A atividade de artes é um campo vasto e enriquecedor que envolve a criação, apreciação e análise de obras visuais, sonoras e performáticas. Ela abrange uma gama ampla de expressões criativas, incluindo pintura, escultura, música, dança, teatro, literatura e design. '
  }
]

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}
function AppWorks() {
  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Destaques</h2>
          <div className="subtitle">O que produzimos</div>
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works => {
              return (
                <Col sm={4} key={works.id}>
                  <div className='portfolio-wrapper'>
                    <a href={works.link}>
                      <Image src={works.image} />
                      <div style={{paddingTop:'80px'}} className='label text-center'>
                        <h3>{works.title}</h3>
                        <p style={{color:'#FFFFFF'}}>{works.subtitle}</p>
                      </div>
                    </a>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>  
    </section>
  );
}
export default AppWorks;