import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import AppBannerPage from '../../../components/bannerPage';

function Missao_visao_valores() {
    return (
         <div>
        <header id='header'>
        <AppHeader />
      </header>
      <AppBannerPage />
        <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
            <br />
            <div className="details-magazine">
                <p className="f-reg f-20">
                    <b style={{textTransform: 'capitalize',color:'#97be4a'}} className="title-apresentation display-6 mb-3  f-24">Missão</b><br />
                    <br />
                    <p >O Instituto Superior Politécnica Privado do Luena (ISPPL) tem como missão: <br />
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Promover a qualificação de alto nível, a produção, transmissão, crítica e difusão do saber, cultura, ciência e tecnologia, através do estudo, da docência e da investigação.  <br />
                     A continuação da sua missão é orientada pela: <br/> <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Produção de conhecimento, assente na excelência da investigação e da docência;<br /> <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Disseminação do saber científico , através de uma oferta formativa distintiva e integral, satisfazendo as necessidades de formação da sociedade e favorecendo a inovação;<br /><i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Tranferência do conhecimento em prol do desenvolvimento económico, tecnológico e social, concorrendo para a melhoria da competividade das empresas e da qualidade de vida dos cidadãos.
                     </p>
                     <br />
                </p>
                <p className="f-reg f-20">
                    <b style={{textTransform: 'capitalize',color:'#97be4a'}} className="title-apresentation display-6 mb-3 f-24" >Visão</b><br />
                    <br />
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Uma instituicão global, conhecida pela qualidade das suas pesquisas e pela excelência da insvestigação nas suas áreas de afirmação, apto de compreender as dinâmicas de transformação da sociedade e de suportar o desenvolvimentoda comunidade em que se integra através da extensão universitária.<br /> O ISPPL, tenciona ainda ser identificada como um instituto: 
                    <br /> 
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Excelente para aprender a ensinar a pesquisar, que possui uma oferta formativa, inovadora, vergável e atraente nas suas três grandes de afirmação(as Ciências Económicas, jurídicas, socias, humanas, saúde e engenharia) e utilizando métodos  de aprendizagem adequadas as exigênci da sociedade;
                    <br /> 
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Que se diferencia pela excelência dos resultados da investigação, reconhecidos internacionalmente e colocados ao serviços do desenvolvimento sustentável;
                    <br />
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Que tem fixada uma cultura de qualidade que lhe possibilita reforçar o seu posicionamento no contexto so ensino superior e acompanhar as melhores referências internacionais em termos de ensino, investigação e governança;
                    <br />
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Que se atinge como um exemplo nacional e internacional num conjunto limitado de áreas de excelência, atraindo os melhores estudantes, docentes e investigadores;
                    <br />
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Que estima a sua importância, criando uma relação de grande proximidade com estudantes, docentes e comunidade envolvente no campo da investigação;
                    <br />
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Que promove uma atmosfera académica assente na diversidade cultural, inclusão, tolerância e responsabilidade social através da investigação;
                    <br />
                    <i class="fa-solid fa-check-double" style={{color:'#97be4a'}}></i> Aberto e completamente integrado na comunidade, assumindo-se como um actor relevante no desenvolvimento económico, social e cultural da região envolvente através da extensão e investigação científica. 
                </p>
                <br />
                <p className="f-reg f-20">
                    <b style={{textTransform: 'capitalize',color:'#97be4a'}} className="title-apresentation display-6 mb-3  f-24" >Valores</b><br />
                    <br />
                    <b style={{color:'#97be4a'}}>Qualidade E Excelência:</b> Esforçar-se para os mais altos padrões, conforme comparado e avaliado pelos pares;
                    <br /> 
                    <b style={{color:'#97be4a'}}>Responsabilidade:</b> Compromisso com o bom uso e responsável dos Recursos Humanos, Fiscais e Físicos que nos são confiados e actualizados;    
                    <br /> 
                    <b style={{color:'#97be4a'}}>Transparência:</b> Abertura ao escrutínio público sobre nossas acções, processos e uso de nossos recursos;  
                    <br />
                    <b style={{color:'#97be4a'}}>Diversidade: </b>Reconhecer que a diversidade e a excelência são mutuamente inclusivas, melhorando o nosso ensino, a erudição e o envolvimento da comunidade, bem como a nossa capacidade de interagir com todas as pessoas;  
                    <br /> 
                    <b style={{color:'#97be4a'}}>Integridade:</b> Consistentemente abraçando e praticando honestidade, verdade e liberdade em tudo o que fazemos;
                    <br />  
                    <b style={{color:'#97be4a'}}>Respeito: </b>Tratando todas as partes interessadas com civilidade e dignidade;
                    <br />  
                    <b style={{color:'#97be4a'}}>Responsabilidade Social, Envolvimento da Comunidade:</b> Contribuindo da melhor forma possível para o bem-estar intelectual, social e económico das comunidades que servimos; 
                    <br /> 
                    <b style={{color:'#97be4a'}}>Rigor:</b> Rigidez na organização, planificação e acompanhamento ou monitoramento dos trabalhos prestados. 
                    <br /> 
                    <br />
                </p>
                <br />
                </div>
                <br /><br />
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                <a href="/instituicao" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Apresentação
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/missao_visao_valores" style={{textDecoration: 'none'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg "style={{color:'#97be4a'}}>
                    Missão, Visão e Valores
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/Qualidade" style={{textDecoration: 'none', borderBottom: '1px solid green', borderTop: '1px solid green'}} className="py-2 text-dark f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Qualidade
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>           
            </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </main>
        <AppLayout />
        </div>
    );
}

export default Missao_visao_valores;