import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 2,
    icon: 'fas fa-snowflake',
    title: 'Gestão de Empresas',
    description: 'Este curso é ideal para quem deseja adquirir habilidades essenciais para administrar e liderar empresas de forma eficaz.'
  },
  {
    id: 3,
    icon: 'fas fa-plug',
    title: 'Pedagogia',
    description: 'Desenvolva habilidades essenciais para transformar a educação com o nosso curso de Pedagogia. Abordamos metodologias inovadoras, práticas pedagógicas e o papel do educador no desenvolvimento integral dos alunos.'
  },
  {
    id: 5,
    icon: 'fas fa-trophy',
    title: 'Sociologia',
    description: 'Desenvolva uma compreensão crítica das estruturas sociais e das relações humanas, e prepare-se para analisar e enfrentar desafios sociais contemporâneos. '
  },
  {
    id: 6,
    icon: 'fas fa-life-ring',
    title: 'Direito',
    description: 'Oferecemos uma formação completa nas áreas de legislação, jurisprudência e prática jurídica, capacitando você a atuar com ética e excelência.'
  },
  {
    id: 7,
    icon: 'fas fa-life-ring',
    title: 'Arquitetura',
    description: 'Transforme suas ideias em realidade com o nosso curso de Arquitetura. Explore o design inovador, a funcionalidade e a sustentabilidade em projetos arquitetônicos.'
  },
  {
    id: 8,
    icon: 'fas fa-life-ring',
    title: 'Gestão Recursos Humanos',
    description: 'Explore técnicas de recrutamento, desenvolvimento de talentos, administração de benefícios e criação de uma cultura organizacional positiva.'
  },
  {
    id: 9,
    icon: 'fas fa-life-ring',
    title: 'Economia',
    description: 'Mergulhe no universo das finanças e políticas econômicas com o nosso curso de Economia, prepare-se para entender e influenciar a tomada de decisões em diversos setores, desde negócios até o governo.'
  },
  {
    id: 10,
    icon: 'fas fa-desktop',
    title: 'Enfermagem',
    description: 'Aprofunde-se em áreas como cuidados críticos, saúde comunitária e administração de medicamentos, e aprenda a atuar com competência e empatia em diversos ambientes de saúde.'
  },
]

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nossos Cursos</h2>
          <div className="subtitle">Cursos que prestamos</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3 style={{color:'#00761F'}}>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;