import React from 'react'
import AppFooter from './footer';

const AppLayout = ({chieldren}) => {
  return (
    <>
    <section>
      <main>
        {chieldren}
      </main>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <footer id="footer">
        <AppFooter />
      </footer>
      </section>
    </>
  )
}

export default AppLayout
