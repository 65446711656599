import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const blogData = [
  {
    id: 1,
    image: require('../assets/Foto/instituition_02.png'),
    time: '',
    title: 'Exames de Acesso',
    description: 'Os exames de acesso são uma parte crucial do processo de admissão em diversas instituições educacionais. Eles são projetados para avaliar suas competências e conhecimentos fundamentais, ajudando a garantir que você esteja preparado para o curso ou programa que deseja ingressar.',
    link: '#'
  },
  {
    id: 2,
    image: require('../assets/Foto/Ortorga_06.png'),
    time: '',
    title: 'IIª Cerimónia de Ortorga',
    description: 'Durante a Cerimónia de Ortorga realizada, uma das atividades centrais foi a entrega dos Títulos de Honra ao Mérito. Esta etapa da cerimónia destacou-se como um momento de grande emoção e prestígio, reunindo homenageados, suas famílias e convidados especiais para reconhecer e celebrar realizações significativas. O Mestre de Cerimônias deu início ao evento com um discurso inspirador, destacando o impacto e a relevância das contribuições dos homenageados para a comunidade e para a sociedade.',
    link: '#'
  },
  {
    id: 3,
    image: require('../assets/Foto/Ortorga_07.jpeg'),
    time: '',
    title: 'Cerimônia de entrega dos diplomas',
    description: 'A cerimônia de entrega dos diplomas é um momento de grande significado e celebração. Normalmente realizada em um ambiente formal, como um auditório ou uma grande sala de eventos, a cerimônia marca a conclusão de um ciclo importante na vida acadêmica dos formandos. O evento começa com uma recepção calorosa dos convidados, que inclui familiares, amigos e membros da instituição de ensino.',
    link: '#'
  }
]

function AppBlog() {
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nossas Melhores Notícias</h2>
          <div className="subtitle">Nossas atividades</div>
        </div>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={4} key={blog.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <time>{blog.time}</time>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default AppBlog;